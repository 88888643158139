"use strict";

/*global  jQuery*/
//$(function () {
jQuery(function ($) {
  //読み込みが完了
  $(window).on('load',function () { 
    console.log('jQuery Page Loaded.');  
    $(".gl_Item").heightLine();
//    $(".FS2_itemPrice_text").heightLine();
    $(".FS2_thumbnail_container").heightLine();
    $(".itemGroup").heightLine();
//    $(".FS2_original_icons").heightLine();
  });
  
  $(".iframe").colorbox({
    iframe:true,
    width:"80%",
    height:"80%",
    opacity: 0.7
  });
  
  $('<div class="bg-bread"><div class="container"></div></div>').insertAfter('.layout_header');
  $('<div class="bg-bread"><div class="container"></div></div>').insertAfter('#FS2_header');
//  $('<div class="bg-bread"><div class="container"></div></div>').insertBefore('.FS2_upper_comment');
  $('.FS2_breadcrumbs').prependTo('.bg-bread .container');
  
  $('<div class="text-right"><a href="/fs/taishodoshop/Logout.html"><img src="/nakajima-assets/img-common/btn-logout.jpg" alt="ログアウト" /></a></div>').insertAfter('#FS2_body_MyPage .pageTitle');
  $('<div class="text-right"><a href="/fs/taishodoshop/Logout.html"><img src="/nakajima-assets/img-common/btn-logout.jpg" alt="ログアウト" /></a></div>').insertAfter('#FS2_body_MyPage .FS2_PageTitle');
  
  /* iOS動作時の調整処理 */
  $(document).on("touchstart", ".menu-btn", function (event) {
    event.stopPropagation();
  });
  /* ハンバーガーメニューによる開閉処理 */
  $(document).on("click", ".menu-btn.close", function () {
    $(".sp-menu").addClass("open");
    $(".cover").addClass("open");
    $(".cover").removeClass("close");
    $(".sp-menu").removeClass("close");
    $(".menu-btn").addClass("open");
    $(".menu-btn").removeClass("close");
  });
  $(document).on("click", ".menu-btn.open,.cover.open", function () {
    $(".sp-menu").addClass("close");
    $(".sp-menu").removeClass("open");
    $(".cover").removeClass("open");
    $(".cover").addClass("close");
    $(".menu-btn").removeClass("open");
    $(".menu-btn").addClass("close");
  });
  
    //ページトップ戻るボタン 表示ギミック
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $(".btn-pagetop").addClass('visible');
    } else {
      $(".btn-pagetop").removeClass('visible');
    }
  });
  
  //ページトップ戻るボタン 動作
  $(document).on("click", ".btn-pagetop", function () {
   console.log('DOM Content Loaded.');
    $('body,html').velocity("scroll", 500);
  });
  
  // スムーズスクロール 
  $(document).on('click', 'a[href*=\\#]:not([href=\\#])', function (event) {
    var href = $(this).attr("href"),
    target = $(href === "#" || href === "" ? 'html' : href);
    target.velocity("scroll", 500);
    return false;
  });
  
//  $('.top-slide').slick({
//    fade: true,
//    dots: true,
////    infinite: true,
//    speed: 300,
//    slidesToShow: 1,
//    centerMode: false,
//    autoplay: true,
//    autoplaySpeed: 3000,
//    variableWidth: true,
//    pauseOnFocus: false,
//    pauseOnHover: false,
//    responsive: [{
//      breakpoint: 800,
//      settings: {
//        centerMode: false,
//        variableWidth: false
//      }
//    }]
//  });
    $('.top-slide').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true,
    speed: 2000,
    fade: true,
  });


  

  
  
});

//touchstartに対応してたらtouchstart、してなければclick
const myTap = window.ontouchstart===null?"touchstart":"click";

// DOM読み込み完了
document.addEventListener('DOMContentLoaded', function(e) {
  console.log('DOM Content Loaded.');
}, false);

// 完全に読み込み完了
window.addEventListener('load', function() {
  console.log('Page Loaded.');
});
